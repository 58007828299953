@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --channel-primary: 20 72 163;
    --channel-primary6: 29 139 171;
    --channel-primary7: 25 124 152;

    --channel-border: 220 220 220;

    --channel-background: 255  255 255;

    --channel-light: 246 250 253;

    --channel-light-dark: 186 230 242;

    --channel-secondary: 111 109 121;
    --channel-in-primary: 255  255 255;
    --channel-text: 1 1 1;
}

input::placeholder {
    color: rgb(var(--channel-secondary));
}